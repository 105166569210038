/* eslint-disable no-underscore-dangle */
import {toArray} from './utils'
import {fetchTokenNumbers} from './Mapi'
import DomReplacer from './DomReplacer'
import {getFromName} from './PhoneFormatters'
import * as Env from './Env'
import EventEmitter from './EventEmitter'
import {ReplacementConf, RotationConfig} from './RotationConfig'
// Used to actually request rotated numbers from MAPI (if necessary), and
// perform the phone number replacement
export default class RotationReplacer {
  public events: EventEmitter

  private watchInterval: number

  private conf: RotationConfig

  private replacers: DomReplacer[]

  constructor() {
    this.events = new EventEmitter()
    this.watchInterval = undefined
  }

  setConfig(conf: RotationConfig) {
    this.conf = conf
    this.replacers = this._buildReplacers()
  }

  _buildReplacers() {
    return toArray(this.conf.replacementConfig())
      .map(conf => this._buildSimpleReplacer(conf.selectors ? conf : {selectors: toArray(conf)}))
      .filter(Boolean)
  }

  _buildSimpleReplacer(conf: ReplacementConf) {
    const formatter = typeof conf.formatter === 'function' ? conf.formatter : getFromName(conf.formatter)

    const repl = new DomReplacer(
      conf.selectors,
      conf.token || this.conf.defaultToken(),
      formatter,
      conf.filters || [],
      conf.numbersToReplace || [],
    )

    repl.setAllowedTokens(conf.allowedTokens || [])
    return repl
  }

  replaceRotated() {
    if (!this.conf.promo()) {
      return Promise.reject(new Error('no promo code for rotation'))
    }

    const allTokens: any = {}

    this.replacers.forEach(repl => {
      const foundTokens = repl.getTokensToReplace()
      Env.info('Found tokens from replacer', foundTokens, repl)
      foundTokens.forEach(t => {
        allTokens[t] = true
      })
    })

    return fetchTokenNumbers(Object.keys(allTokens), this.conf)
      .then(response => {
        toArray(this.replacers).forEach(r => r.performReplacement(response.tokens))
        return response
      })
      .catch(err => {
        Env.error(err)
      })
  }

  watchForReplacementIfConfigured() {
    if (!this.conf.get('watchForReplacement')) {
      return
    }

    this.watchForReplacement(this.conf.get('watchInterval', 2000))
  }

  watchForReplacement(interval = 2000) {
    if (this.watchInterval) {
      clearInterval(this.watchInterval)
    }

    // @ts-ignore
    this.watchInterval = setInterval(() => this.replaceRotated(), interval)
  }
}
