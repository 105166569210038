import {run as baseRun} from './RotationBase'

export function run() {
  return new Promise((resolve, reject) => {
    window.addEventListener('DOMContentLoaded', () => {
      baseRun()
        .then(repl =>
          repl.replaceRotated().then(r => {
            repl.watchForReplacementIfConfigured()
            resolve(r)
          }),
        )
        .catch(reject)
    })
  })
}
